
<template>
  <div style="overflow-y: auto; overflow-x: hidden">
    <a-row :gutter="16" align="top">
      <a-col :span="1"></a-col>
      <a-col
        :span="14"
        class="row-box row-box-height"
        style="margin-right: 40px"
      >
        <a-row>
          <div>
            <img
              style="width: 100%"
              src="../../public/img/党建切图/首页/梧州banner-不可点击-固定-迅捷压缩.png"
            />
          </div>
        </a-row>
        <a-row
          :gutter="[0, 12]"
          style="
            height: 170px;
            margin-top: 25px;
            border-radius: 15px;
            background-color: white;
          "
        >
          <a-col
            :span="6"
            class="buttom-bar-item radiusleft"
            style="background-color: #f3d585"
          >
            <img
              style="width: 150px; height: 90px; margin: auto; display: flex"
              src="../../public/img/党建切图/首页/党建100图标-迅捷压缩.png"
            />
          </a-col>

          <!--
   <a-menu-item
                v-for="item in pictureCarousel"
                :key="item.pic"
                style="
                  height: 110px;
                  width: calc(80%);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  text-align: center;
                  white-space: normal;
                  overflow-wrap:break-word；
                  word-break: break-all;
                  overflow: hidden;
                  border-radius: 10px;
                  background-color: transparent;
                  background: rgba(255, 255, 255, 0.3);
                  border-color: #f3d585;
                "
              >
              <router-link :to="{ pic: item.pic }" style="width: 90%">
                 <img style="width: 50px; height: 50px; margin: auto; display: flex" src={{ item.pic }} />
                </router-link>
              </a-menu-item>

              <img style="width: 50px; height: 50px; margin: auto; display: flex"
              v-for="(v,i) in Carousel" :key ="i" :src="v" />

                <img style="width: 50px; height: 50px; margin: auto; display: flex"
              v-for="(v,i) in items" :key ="i" :src="v" />
              -->
          <a-col span="18" id="Carousel">
            <router-link :to="{ name: '党建100周年' }">
              <img
                style="width: 690px; height: 158px; margin: auto; display: flex"
                v-for="(v, i) in items"
                :key="i"
                :src="v.scenImageAbsolutePath"
                v-show="n == i"
              />
            </router-link>
          </a-col>
        </a-row>
      </a-col>
      <!-- <a-col :span="1"></a-col> -->
      <a-col :span="7" class="row-box row-box-height">
        <div style="height: 32%; margin-bottom: 15px">
          <router-link :to="{ name: '党史学习教育' }">
            <img
              style="width: 100%; height: 100%"
              src="../../public/img/党建切图/首页/党史学习教育-块面点击-迅捷压缩.png"
            />
          </router-link>
        </div>

        <div style="height: 32%; margin-bottom: 15px">
          <router-link :to="{ name: '机关党建工作' }">
            <img
              style="width: 100%; height: 100%"
              src="../../public/img/党建切图/首页/机关党建-迅捷压缩.png"
            />
          </router-link>
        </div>

        <div style="height: 32%">
          <router-link :to="{ name: '党建品牌建设' }">
            <img
              style="width: 100%; height: 100%"
              src="../../public/img/党建切图/首页/党建品牌建设-块面点击-迅捷压缩.png"
            />
          </router-link>
        </div>
      </a-col>
      <a-col :span="1"></a-col>
    </a-row>
  </div>
</template>

<script>
//原旧党建首页展示图
import request from "@/utils/request";
export default {
  data() {
    return {
      zhege: "ceshi",
      n: 0,
      items: [],
      baseURL: "https://wzcpc.gxxqy.cn/admin/",
    };
  },
  methods: {
    getSlider() {
      var that = this;
      request({
        url: "/scrollable/getScrollableImage?scroType=image",
        method: "GET",
        params: null,
      }).then((res) => {
        console.log("res= ", res.data.data);
        that.items = res.data.data[0].scrollableEntity;
        // console.log(that.items)
      });
    },
    play: function () {
      setInterval(this.autoPlay, 2000);
    },
    autoPlay: function () {
      this.n++;
      if (this.n == this.items.length) {
        this.n = 0;
      }
    },
    button1() {
      this.zhege = "测试成功";
    },
  },
  mounted: function () {
    this.play();
    this.getSlider();
  },
};
</script>
<style scoped>
.row-box-height {
  margin-top: 24px;
  height: 740px;
}

.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 531px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
  border-radius: 15px;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}

.buttom-bar-item {
  height: 100%;
  /* 居中 */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.buttom-bar-item-img {
  width: 80%;
  height: 70%;
}

.buttom-bar-item-words {
  width: 80%;
  height: 30%;
  margin: auto;
  display: block;
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  font-size: 20px;
  text-overflow: ellipsis;
}
.radiusleft {
  border-radius: 15px 0 0 15px;
}

.radiusright {
  border-radius: 0 15px 15px 0;
}
</style>
