import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
// import Antd from 'ant-design-vue'
// import 'ant-design-vue/dist/antd.css'
// Vue.use(Antd)

import "ant-design-vue/dist/antd.css";
import {
  Button,
  Layout,
  Row,
  Col,
  Menu,
  Tabs,
  Card,
  Icon,
  Input,
  Carousel,
  List,
  ConfigProvider,
  Breadcrumb,
} from "ant-design-vue";
// import scroll from 'vue-seamless-scroll';

Vue.use(scroll);

Vue.use(Button);
Vue.use(Layout);
Vue.use(Row);
Vue.use(Col);
Vue.use(Menu);
Vue.use(Tabs);
Vue.use(Card);
Vue.use(Icon);
Vue.use(Input);
Vue.use(Carousel);
Vue.use(List);
Vue.use(ConfigProvider);
Vue.use(Breadcrumb);
Vue.config.productionTip = true;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
