import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '首页',
    component: Home
  },
  {
    path: '/partyHistory',
    name: '党史学习教育',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/党史学习教育/partyHistory.vue'),
    redirect: { name: '党史学习教育动员大会' },
    children: [
      { 
        path: 'mobilization',
        name: '党史学习教育动员大会',
        component: () => import('../views/党史学习教育/子菜单/mobilization.vue'),
      },
      {
        path: 'projectStudy',
        name: '开展专题学习',
        component: () => import('../views/党史学习教育/子菜单/projectStudy.vue'),
      },
      {
        path: 'political',
        name: '加强政治引领',
        component: () => import('../views/党史学习教育/子菜单/political.vue'),
      },
      {
        path: 'projectTraining',
        name: '组织专题培训',
        component: () => import('../views/党史学习教育/子菜单/projectTraining.vue'),
      },
      {
        path: 'practice',
        name: '开展“我为群众办实事”实践活动',
        component: () => import('../views/党史学习教育/子菜单/practice.vue'),
      },
      {
        path: 'liveMeeting',
        name: '召开专题组织生活会',
        component: () => import('../views/党史学习教育/子菜单/liveMeeting.vue'),
      }
    ]
  },
  {
    //机关党建工作
    path: '/administrative',
    name: '机关党建工作',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/机关党建工作/administrative.vue'),
  },
  {
    //基层党建工作分类
    path: '/administrative/grassRoots/categoryNews',
    name: '基层党建工作分类新闻',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/机关党建工作/子菜单/categoryNews.vue'),
  },
  {
    //“三会一课”
    path: '/administrative/grassRoots/shyk',
    name: '"三会一课"制度落实情况',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/机关党建工作/子菜单/shyk.vue'),
  },
  {
    //民主评议党员
    path: '/administrative/grassRoots/categoryNews',
    name: '民主评议党员',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/机关党建工作/子菜单/categoryNews.vue'),
    children: [
      { 
        path: 'newsShowModule',
        name: '新闻列表模块',
        component: () => import('../views/机关党建工作/子菜单/newsShowModule.vue'),
      },
    ]
  },


  {
    //党建品牌建设
   path: '/brandBuilding',
    name: '党建品牌建设',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/党建品牌建设/brandBuilding.vue'),
    
  },
  {
    //党建品牌建设
   path: '/brandBuilding/newsPage',
    name: '党建品牌建设-局机关党支部',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/党建品牌建设/子菜单/newsPage.vue'),
    
  },
  {
    //党建100周年
   path: '/yearsCelebra',
    name: '党建100周年',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/机关党建工作/yearsCelebra.vue'),
  },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    //文章路径
    path: '/article/:fromPath/:articleid',
    name: '文章',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/article.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
