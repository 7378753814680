<template>
  <a-config-provider :locale="locale">
    <div id="app" class="custom-body">
      <a-layout id="components-layout-demo-top" class="layout">
        <a-layout-header>
          <a-row type="flex" justify="center" align="middle" style="">
            <a-col :span="1"></a-col>
            <a-col :span="8">
              <div
                class="logo"
                v-bind:class="{ logoNoneDisplay: logoNoneDisplay }"
              >
                <img
                  src="../public/img/党建切图/二级页标题.png"
                  class="logoimg"
                />
              </div>
            </a-col>
            <a-col :span="14">
              <a-menu
                theme="light"
                mode="horizontal"
                :default-selected-keys="['党建品牌建设']"
                :style="{ lineHeight: '119px' }"
                :selectedKeys="[selectedKeys]"
                @click="handleClick"
              >
                <a-menu-item key="党建品牌建设">
                  <router-link :to="{ name: '党建品牌建设' }"
                    >党建品牌建设</router-link
                  ></a-menu-item
                >
                <a-menu-item key="机关党建工作">
                  <router-link :to="{ name: '机关党建工作' }"
                    >机关党建工作</router-link
                  ></a-menu-item
                >
                <a-menu-item key="党史学习教育">
                  <router-link :to="{ name: '党史学习教育' }"
                    >党史学习教育</router-link
                  >
                </a-menu-item>
                <a-menu-item>
                  <router-link :to="{ name: '党建100周年' }">
                    <img
                      src="../public/img/党建切图/首页/标题栏图标-迅捷压缩.png"
                    />
                  </router-link>
                </a-menu-item>
                <a-menu-item key="首页">
                  <router-link :to="{ name: '首页' }">首页</router-link>
                </a-menu-item>
              </a-menu>
            </a-col>
            <a-col :span="1"></a-col>
          </a-row>
        </a-layout-header>
        <div style="overflow-y: auto; height: 100%">
          <router-view></router-view>
        </div>
        <!--<div class="Back back btn" v-show="isShow"  @click="goBack">
          <span>返回上一页</span>
        </div>-->
        <!-- <a-layout-content style="padding: 0 50px">
        <a-breadcrumb style="margin: 16px 0">
          <a-breadcrumb-item>Home</a-breadcrumb-item>
          <a-breadcrumb-item>List</a-breadcrumb-item>
          <a-breadcrumb-item>App</a-breadcrumb-item>
        </a-breadcrumb>
        <div
          :style="{ background: '#fff', padding: '24px', minHeight: '280px' }"
        >
          Content
        </div>
      </a-layout-content>
      <a-layout-footer style="text-align: center">
        Ant Design ©2018 Created by Ant UED
      </a-layout-footer> -->
      </a-layout>
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
  data() {
    return {
      logoNoneDisplay: false,
      locale: zhCN,
      selectedKeys: "首页",
      isShow: false,
    };
  },
  methods: {
    goBack() {
      // console.log('点了返回按钮')
      let _path = this.$route.path;
      if (_path == "/home" || _path == "/index") {
        this.$router.push({
          path: "/login",
        });
      } else {
        this.$router.go(-1); //返回上一层
      }
    },
    init() {
      let _path = this.$route.path;
      if (_path == "/home" || _path == "/login") {
        this.isShow = false;
      } else if (_path.path == "/index") {
        this.isShow = true;
      } else {
        this.isShow = true;
      }
    },
    handleClick(e) {
      console.log("click ", e);
      console.log(this.$route);
      this.selectedKeys = e.key;
    },
  },
  watch: {
    $route(now) {
      //监控路由变换，控制返回按钮的显示
      if (now.path == "/home" || now.path == "/login") {
        this.isShow = false;
      } else if (now.path == "/index") {
        this.isShow = true;
      } else {
        this.isShow = true;
      }
    },
  },
};
</script>
<style lang="scss">
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
.custom-body {
  background-image: url("../public/img/党建切图/首页/底图-迅捷压缩.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
  min-height: 900px;
  width: 100%;
  overflow: hidden;
}

.logoNoneDisplay {
  display: none;
}

#components-layout-demo-top .logo {
  // width: 500px;
  float: left;
  // position: relative;
  // top: 50%; /*偏移*/
  // margin-top: -20px;
}

#components-layout-demo-top .logo .logoimg {
  width: 100%;
}
.ant-layout {
  background: transparent !important;
}

.ant-layout-header {
  height: 119px !important;
  background-color: white !important;
  background-image: url("../public/img/党建切图/顶部-标题栏横条.png") !important;
  border-bottom: 1px solid;
}

.ant-menu-light {
  background: transparent !important;
}

.ant-menu-item {
  float: right;
  color: #f6de8d !important;
  top: 0 !important;
  border-bottom: 0px !important;
  font-size: 20px;
  font-weight: lighter;
}

.ant-menu-item-active {
  float: right;
  color: #f6de8d !important;
  background-color: #990101 !important;
  top: 0 !important;
  border-bottom: 0px !important;
  font-size: 20px;
  font-weight: lighter;
}

.ant-menu-item-selected {
  float: right;
  color: #f6de8d !important;
  border-bottom: 0px !important;
  background-color: #990101;
  background-image: unset;
  font-size: 22px;
  font-weight: 900;
}

.ant-menu-horizontal {
  color: #f6de8d !important;
  border-bottom: 0px !important;
}
.ant-menu-item > a {
  color: #f6de8d !important;
}
.btn {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  color: #f6de8d;
  text-decoration: none;
  background-image: linear-gradient(
    120deg,
    rgba(236, 157, 91, 0.5) 0%,
    rgba(243, 55, 57, 0.5) 100%
  );
  /*background-color: rgba(219,87,5,1);*/
  font-family: "Yanone Kaffeesatz";
  font-weight: 700;
  font-size: 2.5em;
  display: block;
  padding: 10px 20px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  box-shadow: 0px 3px 0px rgba(219, 31, 5, 0.3), 0px 3px 6px rgba(0, 0, 0, 0.3);
  margin: 10px auto;
  /*width: 80%;*/
  text-align: center;

  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -ms-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  transition: all 0.1s ease;
  z-index: 9999;
}

.btn:active {
  box-shadow: 0px 3px 0px rgba(219, 31, 5, 0.3), 0px 3px 6px rgba(0, 0, 0, 0.5);
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  top: initial;
}
.back {
  display: flex;
  flex-direction: column;
  /*height: 2.0rem;*/
  /*line-height: 2.0rem;*/
  font-size: 1.2rem;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  cursor: pointer;
}
</style>
